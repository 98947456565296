import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import VerticalMenu from "./LeftMenu/VerticalMenu";
import { useDispatch } from "react-redux";
import WebService from "../Services/WebService";
import { clientId, loginUserRoleType, tenantId, updateProfileImage } from "../Redux/actions";
import { useEffect } from "react";

const Main = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    meCall();
  }, [])
  
  const meCall = () => {
    return WebService.getAPI({
      action: `me`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        localStorage.setItem("admin_id", res.id);
        localStorage.setItem("admin_email", res.email);
        localStorage.setItem("admin_name", res.first_name + " " + res.last_name);
        localStorage.setItem("profileImage", res.profilePicture)
        dispatch(updateProfileImage(res.profilePicture));
        if (res.role_type === "SYSTEM_ADMIN") {
          dispatch(loginUserRoleType("SYSTEM_ADMIN"));
          // navigate("/dashboard");
        } else if (res.role_type === "ACCOUNT_ADMIN") {
          dispatch(loginUserRoleType("ACCOUNT_ADMIN"));
          dispatch(tenantId(res?.client?.tenantId));
          dispatch(clientId(res?.client?.id));
          // navigate("/client-admin-overview");
        } else {
          dispatch(loginUserRoleType("ADMIN_COMPLIANCE_USER"));
          // navigate("/keywords");
        }
      })
      .catch((error) => {
        return error;
      });
  };
  return (
    <>
      <Header />
      <div id="main-app" className="layout-veritcle-menu">
        <VerticalMenu />
        <div className="app-body kpi-dahboard-page">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Main;
