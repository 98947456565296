import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './Reducer/ProfileReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Combine reducers (if you have more than one)
const rootReducer = combineReducers({
  profile: profileReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

// Persistor to control persistence
export const persistor = persistStore(store);

export default store;

